import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTentors } from "../../../../../Features/Classes/Tentor/Utils/TentorUtils";
import { fetchPrivateSchedule } from "../../../../Classes/Schedule/Private/PrivateUtils";
import AsyncSelectCommon from "../../../../../Component/Learning/Common/SelectOption/AsyncSelect";
import { setFilters } from "../../../../../Features/Classes/Schedule/Private/PrivateSlice";

const FilterTentorPrivate = () => {
    const dispatch = useDispatch();
    const filterTentor = useSelector((state) => state.Tentor.filters);
    const dataTentor = useSelector((state) => state.Tentor.data.data);
    const filterPrivate = useSelector((state) => state.Private.filters);
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        if (filterPrivate["filter[tentor_id]"] === "") {
            setSelectedValue(null);
        } else {
            setSelectedValue({
                value: filterPrivate["filter[tentor_id]"],
                label:
                    dataTentor.find(
                        (item) => item.id === filterPrivate["filter[tentor_id]"]
                    )?.name || "",
            });
        }
    }, [filterPrivate["filter[tentor_id]"]]);

    const promiseOptionTentor = (inputValue, callback) => {
        dispatch(
            fetchTentors({
                ...filterTentor,
                "filter[name]": inputValue,
                sort: "name",
                page: 1,
            })
        )
            .then((response) => {
                const options = response.payload.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                callback(options);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
                callback([]);
            });
    };

    const handleSelectChangeTentor = (selectedOption) => {
        setSelectedValue(selectedOption);
        const newFilters = {
            ...filterPrivate,
            "filter[tentor_id]": selectedOption === null ? "" : selectedOption.value,
            page: 1,
        };
        dispatch(setFilters(newFilters));
        fetchPrivateSchedule(dispatch, newFilters);
    };

    return (
        <AsyncSelectCommon
            label={false}
            placeholder={"Tentor..."}
            isMulti={false}
            promiseOptions={promiseOptionTentor}
            handleSelectChange={(selectedOption) =>
                handleSelectChangeTentor(selectedOption)
            }
            selectedValue={selectedValue}
        />
    );
};

export default FilterTentorPrivate;
