// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import subMaterialReducer from "./Features/Learning/SubMateri/subMaterialSlice";
import addSubMaterialReducer from "./Features/Learning/SubMateri/addSubMaterialSlice";
import attachDetachSubMaterialReducer from "./Features/Learning/SubMateri/attachDetachSubMaterialSlice";
import CategoriesReducer from "./Features/Learning/Categories/categoriesSlice";
import addCategoriesReducer from "./Features/Learning/Categories/addCategoriesSlice";
import MaterialReducer from "./Features/Learning/Materi/materialSlice";
import addMaterialReducer from "./Features/Learning/Materi/addMaterialSlice";
import QuestionReducer from "./Features/Learning/BankQuestions/QuestionSlice";
import TryoutReducer from "./Features/Learning/Tryout/tryoutSlice";
import addTryoutReducer from "./Features/Learning/Tryout/addTryoutSlice";
import attachTryoutSlice from "./Features/Learning/Tryout/attachTryoutSlice";
import detachTryoutSlice from "./Features/Learning/Tryout/detachTryoutSlice";
import addQuestionReducer from "./Features/Learning/BankQuestions/addQuestionSlice";
import importQuestionReducer from "./Features/Learning/BankQuestions/importQuestionSlice";
import courseSlice from "./Features/Learning/Courses/courseSlice";
import addCourseSlice from "./Features/Learning/Courses/addCourseSlice";
import attachDetachCourseSlice from "./Features/Learning/Courses/attachDetachCourseSlice";
import membershipSlice from "./Features/Learning/Memberships/membershipSlice";
import addMembershipSlice from "./Features/Learning/Memberships/addMembershipSlice";
import attachDetachCourseMembershipSlice from "./Features/Learning/Memberships/attachDetachCourseSlice";
import attachDetachTryoutMembershipSlice from "./Features/Learning/Memberships/attachDetachTryoutSlice";
import attachUsersMembershipSlice from "./Features/Learning/Memberships/attachUserSlice";
import userSlice from "./Features/Learning/Users/userSlice";
import userFilteredSlice from "./Features/Learning/Users/userFilteredSlice";
import addUserSlice from "./Features/Learning/Users/addUserSlice";
import historyUserReferralSlice from "./Features/Learning/Users/historyUserReferralSlice";
import historyUserReferralCompactSlice from "./Features/Learning/Users/historyUserReferralCompactSlice";
import historyUserReferralMutationSlice from "./Features/Learning/Users/historyUserReferralMutationSlice";
import ticketSlice from "./Features/Learning/Ticket/ticketSlice";
import voucherSlice from "./Features/Learning/Vouchers/voucherSlice";
import addVoucherSlice from "./Features/Learning/Vouchers/addVoucherSlice";
import mainProgramSlice from "./Features/Learning/MainPrograms/mainProgramSlice";
import addMainProgramSlice from "./Features/Learning/MainPrograms/addMainProgramSlice";
import attachDetachMembershipMainProgramSlice from "./Features/Learning/MainPrograms/attachDetachMembershipSlice";
import transactionSlice from "./Features/Learning/Transactions/transactionSlice";
import setOrderStatusSlice from "./Features/Learning/Transactions/setOrderStatusSlice";
import tentorSlice from "./Features/Classes/Tentor/TentorSlice";
import ClassSlice from "./Features/Classes/Class/ClassSlice";
import addClassSlice from "./Features/Classes/Class/addClassSlice";
import attachDetachUserToClassSlice from "./Features/Classes/Class/attachDetachUserToClassSlice";
import detailMemberClassSlice from "./Features/Classes/Class/detailMemberClassSlice";
import ScheduleSlice from "./Features/Classes/Schedule/ScheduleSlice";
import addScheduleSlice from "./Features/Classes/Schedule/addScheduleSlice";
import attachDetachUserToScheduleSlice from "./Features/Classes/Schedule/attachDetachUserToScheduleSlice";
import QRPresenceSlice from "./Features/Classes/Schedule/QRPresenceSlice";
import detailUserProgressSlice from "./Features/Classes/Schedule/detailUserProgressSlice";
import addTentorSlice from "./Features/Classes/Tentor/addTentorSlice";
import BlogReducer from "./Features/Learning/Blog/blogSlice";
import addBlogReducer from "./Features/Learning/Blog/addBlogSlice";
import Testimony from "./Features/Learning/Testimony/TestimonySlice";
import addTestimony from "./Features/Learning/Testimony/addTestimonySlice";
import PrivateSlice from "./Features/Classes/Schedule/Private/PrivateSlice";

const store = configureStore({
	reducer: {
		subMaterial: subMaterialReducer,
		addSubMaterial: addSubMaterialReducer,
		attachDetachSubMaterial: attachDetachSubMaterialReducer,
		Categories: CategoriesReducer,
		addCategories: addCategoriesReducer,
		Material: MaterialReducer,
		addMaterial: addMaterialReducer,
		Question: QuestionReducer,
		Tryout: TryoutReducer,
		addTryout: addTryoutReducer,
		attachTryout: attachTryoutSlice,
		detachTryout: detachTryoutSlice,
		addQuestion: addQuestionReducer,
		importQuestion: importQuestionReducer,
		Course: courseSlice,
		addCourse: addCourseSlice,
		attachDetachCourse: attachDetachCourseSlice,
		Membership: membershipSlice,
		addMembership: addMembershipSlice,
		attachDetachMembershipCourse: attachDetachCourseMembershipSlice,
		attachDetachMembershipTryout: attachDetachTryoutMembershipSlice,
		attachUsersMembership: attachUsersMembershipSlice,
		User: userSlice,
		userFiltered: userFilteredSlice,
		addUser: addUserSlice,
		historyUserReferral: historyUserReferralSlice,
		historyUserReferralCompact: historyUserReferralCompactSlice,
		historyUserReferralMutation: historyUserReferralMutationSlice,
		Ticket: ticketSlice,
		Voucher: voucherSlice,
		addVoucher: addVoucherSlice,
		MainProgram: mainProgramSlice,
		addMainProgram: addMainProgramSlice,
		attachDetachMainProgram: attachDetachMembershipMainProgramSlice,
		Transaction: transactionSlice,
		setOrderStatus: setOrderStatusSlice,
		Tentor: tentorSlice,
		Class: ClassSlice,
		addClass: addClassSlice,
		attachDetachUserToClass: attachDetachUserToClassSlice,
		detailMemberClass: detailMemberClassSlice,
		Schedule: ScheduleSlice,
		addSchedule: addScheduleSlice,
		attachDetachUserToSchedule: attachDetachUserToScheduleSlice,
		QRCode: QRPresenceSlice,
		detailUserProgress: detailUserProgressSlice,
		addTentor: addTentorSlice,
		Blog: BlogReducer,
		addBlog: addBlogReducer,
		Testimony: Testimony,
		addTestimony: addTestimony,
		Private: PrivateSlice,
	},
});

export default store;
