// import SocialApp from "../Pages/SocialApp";
// import Calender from "../Pages/Calender/Index";
// import SearchResult from "../Pages/Search Result";
// import Draggable from "../Pages/Calender/Draggable Calender";
// import FormValidation from "../Pages/Forms/Form Control/FormValidation";
// import BaseInput from "../Pages/Forms/Form Control/BaseInput";
// import InputGroups from "../Pages/Forms/Form Control/InputGroup";
// import MegaOptions from "../Pages/Forms/Form Control/MegaOptions";
// import RadioCheckbox from "../Pages/Forms/Form Control/RadioCheckbox";
// import Todo from "../Pages/Todo";
// import TodoFirebase from "../Pages/Todo Firebase";
// import Bookmarks from "../Pages/BookMarks";
// import Tasks from "../Pages/Tasks";
// import Contact from "../Pages/Contact";
// import ContactFirebase from "../Pages/Contact/contactFirebase";
// import FormDatapicker from "../Pages/Forms/Form Widgets/FormDatepicker";
// import FormTypeahead from "../Pages/Forms/Form Widgets/FormTypeahead";
// import FormDefault from "../Pages/Forms/Form Layout/FormDefault";
// import FormWizard from "../Pages/Forms/Form Layout/FormWizard";
// import FormDateTime from "../Pages/Forms/Form Widgets/FormDateTime";
// import FormSelect2 from "../Pages/Forms/Form Widgets/FormSelect2";
// import FormSwitch from "../Pages/Forms/Form Widgets/FormSwitch";
// import FormTouchspin from "../Pages/Forms/Form Widgets/FormTouchSpin";
// import FormDateRangepicker from "../Pages/Forms/Form Widgets/FormDateRangepicker";
// import FromClipboard from "../Pages/Forms/Form Widgets/FormClipboard";
// import BlogDetail from "../Pages/Blog/BlogDetail";
// import BlogSingle from "../Pages/Blog/BlogSingle";
// import BlogPost from "../Pages/Blog/BlogPost";
// import LearningList from "../Pages/Learning/LearningList";
// import CardView from "../Pages/Job Search/CardView";
// import ListView from "../Pages/Job Search/ListView";
// import ApplyNow from "../Pages/Job Search/Apply";
// import JobDetails from "../Pages/Job Search/JobDetail";
// import CKEditor from "../Pages/Editor/CkEditor";
// import MDEEditor from "../Pages/Editor/MDEEditor";
// import ACEEditor from "../Pages/Editor/ACECodeEditor";
// import KnowledgeBase from "../Pages/Knowledgebase/KnowledgeBase";
// import KnowledgeCategory from "../Pages/Knowledgebase/KnowledgeCategory";
// import KnowledgeDetails from "../Pages/Knowledgebase/KnowledgeDetails";
// import SamplePage from "../Pages/Sample Page";
// import LeafletMap from "../Pages/Maps/LeafletMap";
// import MapJs from "../Pages/Maps/MapJs";
// import SupportTickit from "../Pages/Support Tickit";
// import FAQ from "../Pages/FAQ";
// import GalleryGrid from "../Pages/Gallery/GalleryGrid";
// import GalleryGridDesc from "../Pages/Gallery/GalleryGridDesc";
// import MasonryGallery from "../Pages/Gallery/MasonryGallery";
// import MasonryGalleryDesc from "../Pages/Gallery/MasonryGalleryDesc";
// import HoverEffects from "../Pages/Gallery/HoverEffect";
// import DataTable from "../Pages/Tables/DataTable";
// import BasicTable from "../Pages/Tables/Reactstrap Table/BasicTable";
// import SizingTable from "../Pages/Tables/Reactstrap Table/Sizingtable";
// import StylingTable from "../Pages/Tables/Reactstrap Table/StylingTable";
// import BorderTable from "../Pages/Tables/Reactstrap Table/BorderTable";
// import DefaultStyle from "../Pages/Buttons/Defaultstyle";
// import FlagIcon from "../Pages/Icons/FlagIcons";
// import FontAwesome from "../Pages/Icons/FontAwesom";
// import IcoIcons from "../Pages/Icons/IcoIcons";
// import ThemifyIcon from "../Pages/Icons/ThemifyIcon";
// import FeatherIcon from "../Pages/Icons/FeatherIcon";
// import WhetherIcon from "../Pages/Icons/WhetherIcon";
// import ApexChart from "../Pages/Charts/ApexChart";
// import ChartistChart from "../Pages/Charts/ChartistChart";
// import ChartJs from "../Pages/Charts/ChartJs";
// import GoogleChart from "../Pages/Charts/GoogleChart";
// import BasicCard from "../Pages/Bonus UI/BasicCard";
// import CreativeCard from "../Pages/Bonus UI/CreativeCard";
// import DraggingCard from "../Pages/Bonus UI/DraggingCard";
// import TabCard from "../Pages/Bonus UI/TabCard";
// import Carousel from "../Pages/Bonus UI/Carousels";
// import BreadcrumbsClass from "../Pages/Bonus UI/Breadcrumb";
// import ImageCroppers from "../Pages/Bonus UI/ImageCropper";
// import Scrollables from "../Pages/Bonus UI/Scrollable";
// import BootstrapNotify from "../Pages/Bonus UI/BootstrapNotify";
// import RatingClass from "../Pages/Bonus UI/Rating";
// import DropzoneClass from "../Pages/Bonus UI/Dropzone";
// import Tours from "../Pages/Bonus UI/Tours";
// import Ribbons from "../Pages/Bonus UI/Ribbon";
// import RangeSlider from "../Pages/Bonus UI/RangeSilder";
// import Pagination from "../Pages/Bonus UI/Pagination";
// import ImageUpload from "../Pages/Bonus UI/ImageUpload";
// import Sticky from "../Pages/Bonus UI/Sticky";
// import TimeLines from "../Pages/Bonus UI/TimeLine";
// import SweetAlerts from "../Pages/Bonus UI/SweetAleart";
// import TreeViews from "../Pages/Bonus UI/TreeView";
// import DragAndDrop from "../Pages/Bonus UI/DragAndDrop";
// import Steps from "../Pages/Bonus UI/Steps";
//START COMPONENT ROUTE CONTAIN
import Default from "../Pages/DashBoard/Default/Default";
import SubMateriList from "../Pages/Learning/SubMateri/SubMateriList";
import AddSubmateri from "../Pages/Learning/SubMateri/AddSubMateri";
import DetailSubMateri from "../Pages/Learning/SubMateri/DetailSubMateri";
import Categories from "../Pages/Learning/Categories/CategoriesList";
import AddCategories from "../Pages/Learning/Categories/CategoriesAdd";
import MateriList from "../Pages/Learning/Materi/MateriList";
import AddMateri from "../Pages/Learning/Materi/MateriAdd";
import DetailMateri from "../Pages/Learning/Materi/DetailMateri";
import BlogList from "../Pages/Blog/BlogList";
import AddBlog from "../Pages/Blog/BlogAdd";
import QuestionList from "../Pages/Learning/BankQuestion/QuestionList";
import AddQuestion from "../Pages/Learning/BankQuestion/QuestionAdd";
import DetailQuestion from "../Pages/Learning/BankQuestion/QuestionDetail";
import ImportQuestionPage from "../Pages/Learning/BankQuestion/ImportQuestion";
import TryoutList from "../Pages/Learning/Tryout/TryoutList";
import AddTryout from "../Pages/Learning/Tryout/TryoutAdd";
import DetailTryout from "../Pages/Learning/Tryout/DetailTryout";
import CourseList from "../Pages/Learning/Course/CourseList";
import CoursePost from "../Pages/Learning/Course/CourseAdd";
import DetailCourse from "../Pages/Learning/Course/DetailCourse";
import MembershipList from "../Pages/Learning/Membership/MembershipList";
import AddMembership from "../Pages/Learning/Membership/MembershipAdd";
import DetailMembership from "../Pages/Learning/Membership/DetailMembership";
import DetailUsersMembership from "../Pages/Learning/Membership/DetailUsersMembership";
import UserList from "../Pages/Learning/Users/UserList";
import AddUser from "../Pages/Learning/Users/UserAdd";
import DetailUser from "../Pages/Learning/Users/UserDetail";
import UserHistoryReferral from "../Pages/Learning/Users/UserHistoryReferral";
import UserHistoryReferralCompact from "../Pages/Learning/Users/UserHistoryReferralCompact";
import UserHistoryReferralMutation from "../Pages/Learning/Users/UserHistoryReferralMutation";
import TicketList from "../Pages/Learning/Ticket/TicketList";
import TicketDetail from "../Pages/Learning/Ticket/TicketDetail";
import TestimonialList from "../Pages/Learning/Users/Testimonial/TestimonialList";
import TestimonialAdd from "../Pages/Learning/Users/Testimonial/TestimonialAdd";
import VoucherPage from "../Pages/Learning/Voucher/VoucherList";
import VoucherAddPage from "../Pages/Learning/Voucher/VoucherAdd";
import VoucherDetailPage from "../Pages/Learning/Voucher/VoucherDetail";
import MainProgramListPage from "../Pages/Learning/MainProgram/MainProgramList";
import MainProgramAddPage from "../Pages/Learning/MainProgram/MainProgramAdd";
import MainProgramDetailPage from "../Pages/Learning/MainProgram/MainProgramDetail";
import TransactionListPage from "../Pages/Learning/Transaction/TransactionList";
//END COMPONENT ROUTE CONTAIN
//START COMPONENT ROUTE CLASS CONTAIN
import ClassListPage from "../Pages/Classes/Class/ClassList";
import ClassAddPage from "../Pages/Classes/Class/ClassAdd";
import DetailUserClassPage from "../Pages/Classes/Class/DetailUserClass";
import DetailScheduleClassPage from "../Pages/Classes/Class/DetailScheduleClass";
import QRPresenceClassPage from "../Pages/Classes/Class/QRPresenceClass";
//END COMPONENT ROUTE CLASS CONTAIN
//START COMPONENT SCHEDULE CLASS CONTAIN
import ScheduleClassAddPage from "../Pages/Classes/Class/Schedule/ScheduleClassAdd";
import DetailScheduleUserProgress from "../Pages/Classes/Class/Schedule/DetailScheduleUserProgress";
import DetailScheduleUserClassPage from "../Pages/Classes/Class/Schedule/DetailScheduleUserClass";
// END COMPONENT SCHEDULE CLASS CONTAIN
//START COMPONENT ROUTE TENTOR CONTAIN
import TentorListPage from "../Pages/Classes/Tentor/TentorList";
import TentorAdd from "../Pages/Classes/Tentor/TentorAdd";
import DetailTentor from "../Pages/Classes/Tentor/DetailTentor";
//END COMPONENT ROUTE TENTOR CONTAIN
//START COMPONENT ROUTE PRIVATE CONTAIN
import PrivateListPage from "../Pages/Classes/Class/Schedule/Private/PrivateScheduleList";
import DetailPrivatePage from "../Pages/Classes/Class/Schedule/Private/DetailPrivateSchedule";
//END COMPONENT ROUTE PRIVATE CONTAIN
import TestPage from "../Pages/Learning/TestPage";

export const routes = [
	// { path: `${process.env.PUBLIC_URL}/bookmarks`, Component: <Bookmarks /> },

	// { path: `${process.env.PUBLIC_URL}/social-app`, Component: <SocialApp /> },

	// {
	// 	path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
	// 	Component: <Calender />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`,
	// 	Component: <Draggable />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/searchresult`,
	// 	Component: <SearchResult />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/forms-control/formValidation`,
	// 	Component: <FormValidation />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/forms-control/baseInput`,
	// 	Component: <BaseInput />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/forms-control/inputGroup`,
	// 	Component: <InputGroups />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/forms-control/megaOptions`,
	// 	Component: <MegaOptions />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/forms-control/radioCheckbox`,
	// 	Component: <RadioCheckbox />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
	// 	Component: <FormDatapicker />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
	// 	Component: <FormTypeahead />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
	// 	Component: <FormDateTime />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/select2`,
	// 	Component: <FormSelect2 />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/switch`,
	// 	Component: <FormSwitch />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
	// 	Component: <FormTouchspin />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
	// 	Component: <FormDateRangepicker />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
	// 	Component: <FromClipboard />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
	// 	Component: <FormDefault />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
	// 	Component: <FormWizard />,
	// },

	// { path: `${process.env.PUBLIC_URL}/todo`, Component: <Todo /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/todofirebase`,
	// 	Component: <TodoFirebase />,
	// },

	// { path: `${process.env.PUBLIC_URL}/tasks`, Component: <Tasks /> },

	// { path: `${process.env.PUBLIC_URL}/contacts`, Component: <Contact /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/contacts/firebase`,
	// 	Component: <ContactFirebase />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/blog/blogdetail`,
	// 	Component: <BlogDetail />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
	// 	Component: <BlogSingle />,
	// },
	// { path: `${process.env.PUBLIC_URL}/blog/blogpost`, Component: <BlogPost /> },

	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/typography/`,
	// 	Component: <Typography />,
	// },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/`, Component: <Avatars /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/`,
	// 	Component: <HelperClasses />,
	// },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/grid/`, Component: <Grid /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/`,
	// 	Component: <TagAndPills />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/`,
	// 	Component: <Progressbars />,
	// },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/modal/`, Component: <Modals /> },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/alert/`, Component: <Alerts /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/popover/`,
	// 	Component: <PopOvers />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/`,
	// 	Component: <Tooltips />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/spinner/`,
	// 	Component: <Spinners />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/`,
	// 	Component: <Dropdowns />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/`,
	// 	Component: <TabBootstrap />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/`,
	// 	Component: <TabLine />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/ui-kits/accordion/`,
	// 	Component: <Accordian />,
	// },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/`, Component: <Shadow /> },
	// { path: `${process.env.PUBLIC_URL}/ui-kits/list/`, Component: <List /> },
	// {
	// 	path: `${process.env.PUBLIC_URL}/jobSearch/cardView`,
	// 	Component: <CardView />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/jobSearch/job-list`,
	// 	Component: <ListView />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/jobSearch/job-detail`,
	// 	Component: <JobDetails />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/jobSearch/job-apply`,
	// 	Component: <ApplyNow />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
	// 	Component: <CKEditor />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
	// 	Component: <MDEEditor />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
	// 	Component: <ACEEditor />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/knowledgebase`,
	// 	Component: <KnowledgeBase />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`,
	// 	Component: <KnowledgeCategory />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`,
	// 	Component: <KnowledgeDetails />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/sample-page`,
	// 	Component: <SamplePage />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/mapsjs`,
	// 	Component: <MapJs />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/leafletmap`,
	// 	Component: <LeafletMap />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/support-ticket`,
	// 	Component: <SupportTickit />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/faq`,
	// 	Component: <FAQ />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/gallery/imageGallery`,
	// 	Component: <GalleryGrid />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`,
	// 	Component: <GalleryGridDesc />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`,
	// 	Component: <MasonryGallery />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`,
	// 	Component: <MasonryGalleryDesc />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/gallery/imageHover`,
	// 	Component: <HoverEffects />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/tables/basictable`,
	// 	Component: <BasicTable />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/tables/sizingtable`,
	// 	Component: <SizingTable />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/tables/stylingtable`,
	// 	Component: <StylingTable />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/tables/bordertable`,
	// 	Component: <BorderTable />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/tables/datatable`,
	// 	Component: <DataTable />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/button`,
	// 	Component: <DefaultStyle />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/flagicon`,
	// 	Component: <FlagIcon />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/fontawesome`,
	// 	Component: <FontAwesome />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/ico`,
	// 	Component: <IcoIcons />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/thimify`,
	// 	Component: <ThemifyIcon />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/feather`,
	// 	Component: <FeatherIcon />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/icons/wheater`,
	// 	Component: <WhetherIcon />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/charts/apex`,
	// 	Component: <ApexChart />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/charts/chartist`,
	// 	Component: <ChartistChart />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/charts/chartjs`,
	// 	Component: <ChartJs />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/charts/google`,
	// 	Component: <GoogleChart />,
	// },

	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
	// 	Component: <BasicCard />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
	// 	Component: <CreativeCard />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
	// 	Component: <DraggingCard />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
	// 	Component: <TabCard />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
	// 	Component: <Carousel />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
	// 	Component: <BreadcrumbsClass />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
	// 	Component: <ImageCroppers />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
	// 	Component: <Scrollables />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
	// 	Component: <BootstrapNotify />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
	// 	Component: <RatingClass />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
	// 	Component: <DropzoneClass />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent`,
	// 	Component: <Tours />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
	// 	Component: <Ribbons />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
	// 	Component: <RangeSlider />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
	// 	Component: <Pagination />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
	// 	Component: <ImageUpload />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
	// 	Component: <Sticky />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
	// 	Component: <TimeLines />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert`,
	// 	Component: <SweetAlerts />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/treeview`,
	// 	Component: <TreeViews />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
	// 	Component: <DragAndDrop />,
	// },
	// {
	// 	path: `${process.env.PUBLIC_URL}/bonus-ui/step`,
	// 	Component: <Steps />,
	// },

	//START ROUTE CONTAIN
	{
		path: `${process.env.PUBLIC_URL}/dashboard/default/`,
		Component: <Default />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/sub-materi-list`,
		Component: <SubMateriList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/sub-materi-add/:id?`,
		Component: <AddSubmateri />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/sub-materi-detail/:id`,
		Component: <DetailSubMateri />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/category-list`,
		Component: <Categories />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/category-add/:id?`,
		Component: <AddCategories />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/materi-list`,
		Component: <MateriList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/materi-add/:id?`,
		Component: <AddMateri />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/materi-detail/:id`,
		Component: <DetailMateri />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/soal-list`,
		Component: <QuestionList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/soal-add/:page?/:index?`,
		Component: <AddQuestion />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/soal-import`,
		Component: <ImportQuestionPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/soal-detail/:id`,
		Component: <DetailQuestion />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/tryout-list`,
		Component: <TryoutList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/tryout-add/:id?`,
		Component: <AddTryout />,
	},
	{
		path: `${process.env.PUBLIC_URL}/learning/tryout-detail/:id`,
		Component: <DetailTryout />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/course-list`,
		Component: <CourseList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/course-add/:id?`,
		Component: <CoursePost />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/course-detail/:id`,
		Component: <DetailCourse />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/membership-list`,
		Component: <MembershipList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/membership-add/:id?`,
		Component: <AddMembership />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/membership-detail/:id`,
		Component: <DetailMembership />,
	},
	{
		path: `${process.env.PUBLIC_URL}/membership/membership-users/:id`,
		Component: <DetailUsersMembership />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/user-list`,
		Component: <UserList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/user-add/:id?`,
		Component: <AddUser />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/user-detail/:id`,
		Component: <DetailUser />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/referrer-list`,
		Component: <UserHistoryReferralCompact />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/referral-order/:id`,
		Component: <UserHistoryReferral />,
	},
	{
		path: `${process.env.PUBLIC_URL}/users/referral-mutation/:id`,
		Component: <UserHistoryReferralMutation />,
	},
	{ path: `${process.env.PUBLIC_URL}/user-tickets`, Component: <TicketList /> },
	{
		path: `${process.env.PUBLIC_URL}/user-tickets/detail/:id`,
		Component: <TicketDetail />,
	},
	{
		path: `${process.env.PUBLIC_URL}/user-testimonials`,
		Component: <TestimonialList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/user-testimonials/add/:id?`,
		Component: <TestimonialAdd />,
	},
	{ path: `${process.env.PUBLIC_URL}/voucher`, Component: <VoucherPage /> },
	{
		path: `${process.env.PUBLIC_URL}/voucher/add/:id?`,
		Component: <VoucherAddPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/voucher/detail/:id`,
		Component: <VoucherDetailPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/main-program`,
		Component: <MainProgramListPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/main-program/add/:id?`,
		Component: <MainProgramAddPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/main-program/detail/:id`,
		Component: <MainProgramDetailPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/transactions`,
		Component: <TransactionListPage />,
	},
	{ path: `${process.env.PUBLIC_URL}/test`, Component: <TestPage /> },
	//END ROUTE CONTAIN
	//START ROUTE CLASS CONTAIN
	{
		path: `${process.env.PUBLIC_URL}/classes/class-list`,
		Component: <ClassListPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/class-add/:classId?`,
		Component: <ClassAddPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/class-user-detail/:classId`,
		Component: <DetailUserClassPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/class-schedule-detail/:classId`,
		Component: <DetailScheduleClassPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/schedule-class-add/:classId/:scheduleId?`,
		Component: <ScheduleClassAddPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/schedule-class-detail/:classId/:scheduleId`,
		Component: <DetailScheduleUserClassPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/qr-presence-class/`,
		Component: <QRPresenceClassPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/user-schedule-progress/:classId/:userId/:userName`,
		Component: <DetailScheduleUserProgress />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/private-list`,
		Component: <PrivateListPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/private-detail/:privateId`,
		Component: <DetailPrivatePage />,
	},
	//END ROUTE CLASS CONTAIN
	//START ROUTE TENTOR CONTAIN
	{
		path: `${process.env.PUBLIC_URL}/classes/tentor-list`,
		Component: <TentorListPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/tentor/detail/:id`,
		Component: <DetailTentor />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/tentor/add`,
		Component: <TentorAdd />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/tentor/edit/:id`,
		Component: <TentorAdd />,
	},
	{
		path: `${process.env.PUBLIC_URL}/classes/private-list`,
		Component: <TentorListPage />,
	},
	//END ROUTE TENTOR CONTAIN
	{
		path: `${process.env.PUBLIC_URL}/blog-list`,
		Component: <BlogList />,
	},
	{
		path: `${process.env.PUBLIC_URL}/blog-add/:id?`,
		Component: <AddBlog />,
	},
];
