import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input, Collapse, Label, Button } from 'reactstrap';
import { Search } from 'react-feather';
import { Filter } from '../../../../Constant';
import { H5, Btn } from '../../../../AbstractElements';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../Features/Classes/Tentor/TentorSlice';
import { fetchTentor } from '../../../Classes/Tentor/TentorUtils';
import { resetState } from "../../../../Features/Learning/Vouchers/addVoucherSlice";
import RadioFilter from '../../../../Component/Learning/Common/Filter/RadioFilter';
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';

const FindTentor = () => {
  const [isFilter, setIsFilter] = useState(true);
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Tentor.filters)
  const [selectedSortValue, setSelectedSortValue] = useState(null)

  const handleSortBy = (e) => {
    const filterValue = e.target.value;
    dispatch(setFilters({
      ...filters,
      [e.target.name]: filterValue,
    }));
  };

  const handleAdd = (e) => {
    dispatch(resetState());
  };


  const options = [
    { value: '-name', label: 'A-Z' },
    { value: 'name', label: 'Z-A' },
    { value: '-id', label: 'Terbaru' },
    { value: 'id', label: 'Terlama' },
  ];

  const handleSelectChangeSort = (selectedOption) => {
    setSelectedSortValue(selectedOption);
    const newFilters = {
      ...filters,
      'sort': selectedOption === null ? '' : selectedOption.value,
    }
    dispatch(setFilters(newFilters));
    fetchTentor(dispatch, newFilters);
  }

  const handleClickSearch = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
      'page': 1,
    };
    dispatch(setFilters(newFilters));
    fetchTentor(dispatch, newFilters);
  }

  const handleResetFilter = (e) => {
    e.preventDefault()
    const newFilters = {
      ...filters,
        'filter[id]': '',
        'filter[name]': '',
        'sort': '',
    }
    dispatch(setFilters(newFilters));
    fetchTentor(dispatch, newFilters);
  }

  return (
    <Fragment>
      <Col xl="12">
        <div className="job-filter">
          <div className="faq-form">
            <Row className="m-0">
              <Col className="d-flex justify-content-start align-items-center" xl="3">
                <Link
                  to={`${process.env.PUBLIC_URL}/classes/tentor/add`}
                  onClick={handleAdd}>
                  <Btn attrBtn={{ color: "primary" }}>
                    <i className="fa fa-plus-circle fa-lg"></i> Tambah Tentor
                    Baru
                  </Btn>
                </Link>
              </Col>
              <Col className="mt-2 d-flex justify-content-end align-items-center px-0" xl="9">
                <Row>
                  <Col className="d-flex justify-content-between align-items-center">
                    <Input className="form-control" type="text" placeholder="Cari tentor.." 
                      value={filters['filter[name]']}
                      onKeyUp={(e) => e.key === 'Enter' ? handleClickSearch(e): null}
                      onChange={(e) => {
                        dispatch(setFilters({
                          ...filters,
                          'filter[name]': e.target.value
                        }));
                      }}
                    />
                    <Search className="search-icon" />
                  </Col>
                  <Col>
                    <Select
                      options={options}
                      className="js-example-basic-single"
                      isMulti={false}
                      isClearable={true}
                      placeholder="Sort by.."
                      onChange={handleSelectChangeSort}
                      value={selectedSortValue}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end align-items-center px-0">
                    <Button color="warning me-2" onClick={handleResetFilter}>Reset</Button>
                    <Button color="primary me-2" onClick={handleClickSearch}>Filter</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default FindTentor;