import {createSlice} from '@reduxjs/toolkit';
import {fetchPrivateSchedules} from './Utils/PrivateUtils';

const PrivateSlice = createSlice({
    name: 'PrivateSchedules',
    initialState: {
        filters: {
            "filter[id]": "",
            "filter[tentor_id]": "",
            "filter[duration]": "",
            "filter[total_student]": "",
            "filter[tentor.status]": "",
            "date": "",
            "start_date": "",
            "end_date": "",
            "sort": "",
            "page": "",
        },
        data: [],
        detail: {},
        isLoading: false,
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setDetail: (state, action) => {
            state.detail = action.payload;
        },
    },
    extraReducers: {
        [fetchPrivateSchedules.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchPrivateSchedules.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        [fetchPrivateSchedules.rejected]: (state) => {
            state.isLoading = false;
        },
    }
});

export const {setFilters, setDetail} = PrivateSlice.actions;

export default PrivateSlice.reducer;