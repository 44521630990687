import { fetchPrivateSchedules } from '../../../../Features/Classes/Schedule/Private/Utils/PrivateUtils';
import Swal from 'sweetalert2';

/* **************************************************** */
/* CRUD Private Schedule                                */
/* **************************************************** */
export const fetchPrivateSchedule = (dispatch, filters, refetch) => {
    dispatch(fetchPrivateSchedules(filters));
}

/* **************************************************** */
/* Pagination                                           */
/* **************************************************** */

export const handleBackPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page > 1) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page - 1
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleNextPagination = (pagination, filters, dispatch, setFilters, setRefetch) => {
    if (pagination.current_page < pagination.last_page) {
        dispatch(setFilters({
            ...filters,
            page: pagination.current_page + 1
        }));
        setRefetch(prevRefetch => !prevRefetch);
    }
}

export const handleClickPagination = (page, filters, dispatch, setFilters, setRefetch) => {
    dispatch(setFilters({
        ...filters,
        page: page
    }));
    setRefetch(prevRefetch => !prevRefetch);
}